import React from 'react';
import Model from '../Model';
import styles from './styles.module.scss';
import Loader from 'react-loader-spinner';
const WrongNetworkModel = (props) => {
    return (
        <Model
            visible={props.visible}
            title={'Wrong network'}
            className={`${styles.wallet_model}`}
            onHide={props.onHide}
            extraImage={
                <img
                    style={{ marginLeft: '-23px' }}
                    src="/assets/img/covers/Text.png"
                    alt="metamask wallet"
                />
            }
            showClose={0}
        >
            <p className={styles.text}>
                Please connect to Ethereum{' '}
                {process.env.NEXT_PUBLIC_CHAIN_ID == '1'
                    ? 'mainnet'
                    : 'Rinkeby Test'}{' '}
                OR Polygon Mumbai{' '}
                {process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID == '137'
                    ? 'mainnet'
                    : 'Test'}{' '}
                network.
            </p>
            <br />
            <div style={{ textAlign: 'center' }}>
                <Loader
                    type="ThreeDots"
                    color="#7557BB"
                    height={50}
                    width={50}
                    timeout={3000000} //3 secs
                />
            </div>
        </Model>
    );
};

export default WrongNetworkModel;
