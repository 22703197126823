import React from 'react';
import useDarkOverlay from '../../Hooks/DarkOverlay';
import styles from './styles.module.scss';

const Model = (props) => {
    const handleDarkOverlay = (visible) => {
        if (visible) {
            document.querySelector('body').classList.add('dark_overlay');
        } else {
            document.querySelector('body').classList.remove('dark_overlay');
        }
        return <></>;
    };

    return (
        <div
            className={`${styles.nft_model_wrapper} ${props.className} ${
                props.visible ? styles.show : styles.hide
            }`}
            style={props.style}
        >
            <div className={`${styles.nft_model} ${props.modelClass}`}>
                {props.extraImage}
                <div className={styles.header}>
                    <h3
                        className={
                            props.titleClass &&
                            `${styles.headerTitle} ${styles.title}`
                        }
                    >
                        {props.title}
                    </h3>
                    {props.showClose == 0 ? null : (
                        <span
                            onClick={() => props.onHide(true)}
                            className={styles.close}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 6L6 18"
                                    stroke="#CCCCCC"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="#CCCCCC"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>
                    )}
                </div>
                <div className={styles.body}>{props.children}</div>
            </div>
            {process.browser ?? handleDarkOverlay(props.visible)}
        </div>
    );
};

export default Model;
